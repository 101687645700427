@import '/apps/wt/webtools/sparta_build_server/tmp/EE81542B/artifacts/components/utilities/global/sparta-style-utility/4.9.4/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
@import 'config.scss';

.#{$moduleNameSpace} {
  display: inline-block;

  &--clearfix::before,
  &--clearfix::after {
    content: ' ';
    display: table;
  }

  &--clearfix::after {
    clear: both;
  }

  .boa-social--follow {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
  }

  .boa-social--header {
    color: #6b5e51;
    flex-basis: 100%;
    font-family: $roboto-bold-family;
    font-size: rem-calc(16px);
    margin: 0 0 $spacing-12 0;
    line-height: 24px;
  }

  .boa-social--network {
    display: block;
    margin: 0 10px 10px 0;
    transition: all $spa-standard-transition-time $spa-standard-transition-easing;

    &:last-of-type {
      margin-right: 0;
    }

    &:focus {
      outline: 1px dotted #333;
      outline-offset: 2px;
    }

    &:hover {
      transform: scale(1.25);
    }

    path {
      transition: all $spa-standard-transition-time $spa-standard-transition-easing;
    }
  }

  .boa-social--facebook,
  .boa-social--googleplus,
  .boa-social--instagram,
  .boa-social--pinterest,
  .boa-social--youtube,
  .boa-social--linkedin,
  .boa-social--twitter {
    height: 30px;
    width: 30px;
  }

  .boa-social--ada-hidden {
    display: inline-block;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0;
  }



  .boa-social-size--small {
    .boa-social--network {
      height: 44px;
      margin: 0;
      padding: 10px; 
      width: 44px;
    }

    a:first-of-type {
      margin-left: -10px;
    }

    .boa-social--header {
      margin-bottom: $spacing-8;
    }
  }


  .boa-social-skin--white {
    .boa-social--header {
      color: $white;
    }


    @include social-icon-base-fill($white);

    .boa-social--network:focus {
      outline: 1px dotted $white;
      transform: scale(1.25);
    }
  }
}

.spa-dialog {
  .boa-social--hide-interstitial {
    display: none;
  }
  .spa-btn--primary {
    margin-right: $spacing-20;
  }
  .spa-btn-footer {
    @media #{$small-only} {
      flex-direction: column;
      .spa-btn {
          margin: $spacing-8 0;
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
      }
    }
  }
}


.boa-social--modal {
  ul {
    margin-left: 0;

    li {
      list-style: outside none none;
    }
  }

  .boa-social--hide-interstitial {
    display: none;
  }

  .boa-social--twitter-list {
    margin-bottom: 10px;
  }

  .boa-social--twitter-handle {
    margin-bottom: 10px;
  }

  .boa-social--buttons {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  .boa-social--button {
    margin-right: 20px;

    @media #{$small-only} {
      margin-bottom: 20px;
      width: 100%;
    }

    &:last-of-type {
      margin-bottom: 0;
      margin-right: 0;
    }
  }
}


.modal-flex .boa-social--modal {
  .content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}


[data-module='global-footer-module'] .#{$moduleNameSpace} {
  .boa-social--network[disabled],
  .boa-social--disabled {
    @include social-icon-base-fill($color-gray-s10);
  }

  .boa-social--network {
    border: 1px solid transparent;
    border-radius: 2px;
    margin: 0 $spacing-5;
    padding: 9px;
    height: 44px;
    width: 44px;

    @media #{$xsmall-only} {
      margin: 0;
    }

    &:hover,
    &:focus,
    &:active {
      border-color: $white;
      outline: 0;
      transform: none;
    }
  }
}
